<template>
  <b-form>
    <!-- Nome -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-6">
            <input-default
              v-model="viewModel.Nome"
              :label="$t('sectionPbm.namePbm')"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- Observação -->
      <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-6">
            <input-default
              v-model="viewModel.observacao"
              :label="$t('sectionPbm.observationPbm')"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import InputDefault from "@core/components/commons/inputs/InputDefault";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputDefault,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
