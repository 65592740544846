<template>
  <div>
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralPbmInformation class="mt-8" :viewModel.sync="viewModel" />
        </b-tab>
      </b-tabs>
    </section>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar"
        :onClick="redirectUrl"
      />
    </footer>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPbmInformation from "./GeneralPbmInformation";
import Button from "@core/components/commons/button/Button";

export default {
  name: "ManagePbmView",
  components: {
    BTabs,
    BTab,
    Button,
    GeneralPbmInformation,
  },
  data() {
    return {
      viewModel: {
        Nome: null,
        observacao: null,
      },
    };
  },
  created() {
    this.RecuperarPbm(this.$router.currentRoute.params.id);
  },
  methods: {
    RecuperarPbm(id) {
      this.$http({
        url: `/pbm/obter/${id}`,
        method: "Get",
      }).then((response) => {
        this.viewModel = {
          Nome: response.data.nome,
          observacao: response.data.observacao,
        };
      });
    },
    redirectUrl() {
      return this.$router.push("/pbm");
    },
  },
};
</script>

<style></style>
